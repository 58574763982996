import React  from 'react'
import Layout from '../components/layout'
import { Container, Row, Col } from 'reactstrap' 
import banner from '../images/personal-solution-banner.jpg' 
import { BookAnAppoinment } from '../components/book0an-apoinment'
import personal1 from '../images/personal1.jpg'
import personal2 from '../images/personal2.jpg'
import personal3 from '../images/personal3.jpg'
import logo from '../images/logo-black.png' 
import golf from '../images/golf.jpg'
import { seoData } from '../data/seo-data'

const PersonalSolutions = () => {
 
    /*
 

- John Bourne -
    */
    const wishs = [
        'Superannuation investment',
        'Life Insurance',
        'Total and Permanent Disability Insurance',
        'Retirement income streams',
        'Self Managed Superannuation Fund (SMSF)',
        'Property investment (advice only)',
        'Estate planning',
        'Income protection',
        'Retirement planning',
        'Transition to retirement',
        'Critical illness insurance',
        'Centrelink payments',

    ]
    const steps = [
        {
            text: "Setting Goals & Objectives",
            color: '#15abe3'
        }, {
            text: "Cashflow Advice",
            color: '#ffd400'
        }, {
            text: "Debt Advice",
            color: '#15abe3'
        }, {
            text: "Investment Advice",
            color: '#ffd400'
        },
        {
            text: ' RISK ADVICE', color: '#15abe3'
        }, {
            text: "Retirement Advice",
            color: '#ffd400'
        }
    ]
    return (
        <Layout
            seo={{
                title: seoData.personalSolutions.title, description: seoData.personalSolutions.description, keyword: ''
            }}
            banner={<FreeConsulTationBanner />}
            navStayle="SIMPLE"
            setUid={() => {} }
        >
            
            <section style={{ marginBottom: 50, marginTop: 50 }}>
                <Container style={{ textAlign: "center" }}>
                    <Row className="justify-content-md-center" style={{ marginBottom: 50 }} >
                        <Col md={3} >
                            <img src={personal1} style={{ width: '100%' }} alt="" />
                        </Col>
                        <Col md={3} >
                            <img src={personal2} style={{ width: '100%' }} alt="" />
                        </Col>
                        <Col md={3} >
                            <img src={personal3} style={{ width: '100%' }} alt="" />
                        </Col>
                    </Row>
                    <h5>At Solutions we help our clients prioritise what is most important to them and what can wait. Although our main focus is on clients who are involved in mining, we use the same method for every client. We find out “What do you want?” and “Why do you want it?”.  These are two questions that many struggle to answer. Therefore, we work with you to get clarity on your life goals and objectives.</h5>
                    <h5>Only once we have the answers to those two questions above, we then look at your current financial position, and together decide if we need to make changes with the sole purpose of working towards your goals. </h5>
                    <h5>We are aware that life can take twists and turns and nothing remains constant. We structure our plans for our clients to be flexible and transparent, so that you can have confidence during your journey with us </h5>
                </Container>
            </section>
            <section style={{ marginTop: 50, padding: "50px 0 30px 0" }}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <h4>Examples of specific advice we provide to our clients:</h4>
                            <Row>
                                {wishs.map(w => {
                                    return (
                                        <Col md={6}><p style={{ fontWeight: 500 }}>- {w}</p></Col>
                                    );
                                })}
                            </Row>
                        </Col>
                        <Col md={6}>
                            <h4>Examples of areas where we have helped clients:</h4>
                            <Row>
                                {steps.map(s => {
                                    return (
                                        <Col md={4} style={{ marginBottom: 20 }} >
                                            <div className="steps" style={{ backgroundColor: s.color, textAlign: "center", textTransform: 'uppercase', fontWeight: 800, fontSize: 18, minHeight: 150 }} >
                                                <span className="isi" >{s.text}</span>
                                            </div>

                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{ marginBottom: 50, padding: "150px 0 0 0", background: `url(${golf})`, backgroundSize: 'cover', backgroundPosition: 'bottom center' }}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div style={{ backgroundColor: 'rgba(1, 107, 146, 0.88)', padding: 20, color: '#fff', marginTop: 130 }}>
                                <p style={{ fontWeight: 500 }}><i className="fas fa-quote-left pr-2"></i> We found Marc easy to talk to, personable and came across as a straight shooter. He was happy to provide assistance whether or not you went with his company… He has provided some level headed guidance for my finances<i className="fas fa-quote-right pr-2" /></p>
                                <p style={{ color: '#d4ca5a', fontWeight: 800 }}>- John Bourne -</p>
                            </div>
                        </Col>
                        
                    </Row>
                </Container>
            </section>
             
            <BookAnAppoinment />
        </Layout>
    )
}

const FreeConsulTationBanner = () => {
    return (
        <>
            <div style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'top center', minHeight: 750 }}>
                <Container>
                    <Row style={{ paddingBottom: 50, textAlign: "center" }}>
                        <Col md={6} ></Col>
                        <Col md={6} >
                            <div style={{ marginTop: '50%', background: 'rgba(1, 107, 146, 0.75)', padding: '10px 0' }}>
                                <h3 className="header-font" style={{ color: '#fff', fontSize: '5em', marginBottom: 0, width: 'auto' }}>Personal </h3>
                                <img style={{ marginTop: -37 }} src={logo} alt="" />
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default PersonalSolutions

